export const COMPONENTS = {
  BARCODE: 'barcode',
  COVID_TEST_RESULTS: 'covidResults',
  DASHBOARD: 'dashboard',
  DEMO: 'demo',
  DEVELOPER: 'developer',
  LAB: 'lab',
  LAB_DASHBOARD: 'labDashboard',
  ORDERS: 'orders',
  SANDBOX_DASHBOARD: 'sandboxDashboard',
  STOREFRONT: 'storefront',
  TEAM: 'team',
  TEST_RESULTS: 'testResults',
  CUSTOMERS: 'customers',
};

export const PERMISSIONS = {
  BARCODES_ADD: 'barcodes_add',
  CLINIC_COVID_RESULTS: 'clinic_covid_results',
  CLINIC_ORDER: 'clinic_order',
  CLINIC_TEST_RESULTS: 'clinic_test_results',
  CLINIC_VIEW: 'clinic_view',
  DEVELOPER: 'developer',
  LAB_VIEW: 'lab_view',
  MANAGE_TEAM: 'manage_team',
  STOREFRONT: 'storefront',
  CLINIC_USERS: 'clinic_users',
};

export const PERMISSIONS_PER_COMPONENT = {
  [COMPONENTS.BARCODE]: PERMISSIONS.BARCODES_ADD,
  [COMPONENTS.COVID_TEST_RESULTS]: PERMISSIONS.CLINIC_COVID_RESULTS,
  [COMPONENTS.DASHBOARD]: PERMISSIONS.CLINIC_VIEW,
  [COMPONENTS.DEMO]: PERMISSIONS.DEVELOPER,
  [COMPONENTS.DEVELOPER]: PERMISSIONS.DEVELOPER,
  [COMPONENTS.LAB]: PERMISSIONS.LAB_VIEW,
  [COMPONENTS.LAB_DASHBOARD]: PERMISSIONS.LAB_VIEW,
  [COMPONENTS.ORDERS]: PERMISSIONS.CLINIC_ORDER,
  [COMPONENTS.SANDBOX_DASHBOARD]: PERMISSIONS.CLINIC_VIEW,
  [COMPONENTS.STOREFRONT]: PERMISSIONS.STOREFRONT,
  [COMPONENTS.TEAM]: PERMISSIONS.MANAGE_TEAM,
  [COMPONENTS.TEST_RESULTS]: PERMISSIONS.CLINIC_TEST_RESULTS,
  [COMPONENTS.CUSTOMERS]: PERMISSIONS.CLINIC_USERS,
};
