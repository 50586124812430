import { Link } from '@reach/router';

export const MenuItem = ({ href, label, linkClassName, Icon }) => (
  <li key={`${href}-${label}`}>
    {href.startsWith('http') ? (
      <a
        href={href}
        title={label}
        className="h-12 flex px-4 items-center bg-gradient-to-r via-gray-100 to-gray-100 no-underline hover:from-gray-100"
      >
        <Icon className="text-2xl mr-3" />
        <div>{label}</div>
      </a>
    ) : (
      <Link className="h-16 flex px-6" to={href} getProps={linkClassName}>
        <Icon className="text-2xl mr-3" />
        <div>{label}</div>
      </Link>
    )}
  </li>
);
