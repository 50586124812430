import config from '../config';

export const getCookie = (value, expiry) =>
  `${config.cookieName}=${value || ''}; expires=${expiry.toUTCString()};${!config.isDevelopment ? ` Secure;` : ''} Domain=${
    config.cookieDomain
  }; Path=/; SameSite=Lax;`;

export const deleteCookie = () => {
  const expiryTime = new Date();
  // expiry in the past
  expiryTime.setTime(0);
  document.cookie = getCookie(null, expiryTime);
};

export const setCookie = (value) => {
  const expiryTime = new Date();
  // 60min expiry
  expiryTime.setTime(expiryTime.getTime() + 60 * 60 * 1000);

  document.cookie = getCookie(value, expiryTime);
};
