import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import * as _ from 'lodash';
import { LogoChronomics } from '../../icons';
import { ResponsiveImage } from './ResponsiveImage';

export const joinClassNames = (className) => {
  const baseClassName = 'h-full';
  if (!className || !_.isString(className)) {
    return baseClassName;
  }
  return `${baseClassName} ${className}`;
};

export const LogoWithLink = ({ className, customLogoUrl, height, path, title, width }) => {
  const classNames = joinClassNames(className);
  return (
    <Link title={title} to={path} className="flex justify-left items-left h-full ml-5">
      {!customLogoUrl && <LogoChronomics width={`${width}`} height={`${height}`} className={classNames} />}
      {customLogoUrl && (
        <ResponsiveImage className={` max-w-fit max-h-fit object-contain ${classNames}`} alt="branding-logo" src={customLogoUrl} />
      )}
    </Link>
  );
};

LogoWithLink.propTypes = {
  className: PropTypes.string.isRequired,
  customLogoUrl: PropTypes.string,
  height: PropTypes.number,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
};

LogoWithLink.defaultProps = {
  height: 18,
  width: 100,
};
